
@use "sass:color";
/* SCSS HEX */
$steel-blue: #3f7cacff;
$light-gray: #dad2d8ff;
$carrot-orange: #ec9a29ff;
$carnelian: #a8201aff;
$black: #000000ff;

//test
$theme-colors: (
    "primary": $steel-blue,
    "secondary": $carrot-orange,
    "primary-muted": color.adjust($steel-blue, $saturation:  -50%),
    "secondary-muted":color.adjust($carrot-orange, $saturation:  -50%),
    "success": #198754,
    "info": #0dcaf0,
    "warning": #ffc107,
    "danger": #dc3545,
    "light": #d9d9d9,
    "dark": #737373,
    "lilac": #e5d7e4,
    "light-grey": #d9d9d9,
    "dark-grey": #737373,
);

// Links
$link-color: map-get($theme-colors, "primary");
$link-hover-color: map-get($theme-colors, "secondary");

// Body.
body {
    margin: 0;
    padding: 0;
    font-family: "Open Sans", sans-serif !important;
    font-size: 16px;
    color: #333;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

select.form-control,
textarea.form-control,
input.form-control {
    font-size: 16px;
}
input[type=file] {
    width: 100%;
}

.clickable {
    cursor: pointer;
}

svg {
    // This'll take care of our SVGs.
    fill: currentColor;
}

@import "~bootstrap/scss/bootstrap";
  
// Body.
body {
    font-family: "Open Sans", sans-serif !important;
}

.appContainer {
    min-height: 100vh;
}

a.btn {
    text-decoration: none;
}

.narrowform {
    width: 100%;
    max-width: 400px;
    padding: 15px;
    margin: 0 auto;
}

/**
 * Use these classes to combine multiple text inputs into a single block.
 */
.topinput {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.middleinput {
    margin-bottom: -1px;
    border-radius: 0;
}

.bottominput {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.recaptchaContainer > div > div {
    display: flex;
    place-content: center;
}