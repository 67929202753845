@import "../App.scss";

// Navbar.
.title {
  font-weight: bold;
  color: map-get($theme-colors, "primary");
}

.nav-item {
  min-height: 2em;
}

.primarySvg {
  height: 60px;
  color: map-get($theme-colors, "primary");
  fill: map-get($theme-colors, "primary");
  border: none;
}
